import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import IBrand from 'src/models/IBrand';
import BrandsAPI from 'src/api/BrandsAPI';
import { useNavigate } from 'react-router-dom';
import { MyButton } from 'src/components/ui/myButton';

const Brands = () => {
    const [brands, setBrands] = useState<IBrand[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const getBrands = (limit: number, offset: number) => {
        BrandsAPI.getBrands(limit, offset)
            .then(response => {
                if (response.status <= 204) {
                    setBrands(response.data.brands);
                    setCount(response.data.amount);
                } else {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                }
            })
            .catch(() => {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            })
    }

    const handleClick = (id?: string) => {
        if (id) {
            navigate(`/brand/${id}`);
        } else {
            navigate(`/brand`);
        }
    }

    useEffect(() => {
        getBrands(10, 0);
    }, []);

    return (
        <div className={styles.body}>
            <div className={styles.buttonContainer}>
                <MyButton handleClickProps={() => handleClick()}>Создать бренд</MyButton>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>Интервалы сообщений</th>
                        </tr>
                    </thead>
                    <tbody className={styles['']}>
                        {brands.map((item) => (
                            <tr className={styles.tableTr} key={item.id} onClick={() => handleClick(item.id)}>
                                <td>
                                    <div className={styles.tdTextName}>{item.name}</div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {`${item.first_push_interval || '-'} / ${item.second_push_interval || '-'}`}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <PaginationComponent
                    count={count}
                    limit={10}
                    page={page}
                    setPage={setPage}
                    onChange={getBrands}
                />
            </div>
        </div>
    )
}

export default Brands;
