import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import BrandsAPI from 'src/api/BrandsAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomInput } from 'src/components/ui/customInput';
import { MyButton } from 'src/components/ui/myButton';
import IBrand from 'src/models/IBrand';
import IEditBrandRequest from 'src/models/request/IEditBrandRequest';
import Channels from './channels';

const BrandPage = () => {
    const [currentBrand, setCurrentBrand] = useState<IBrand | null>(null);
    const [name, setName] = useState<string>('');
    const [assistant_id, setAssistant_id] = useState<string>('');
    const [wazzup_api_key, setWazzup_api_key] = useState<string>('');
    const [openai_api_key, setOpenai_api_key] = useState<string>('');
    const [yclients_api_key, setYclients_api_key] = useState<string>('');
    const [yclients_company_id, setYclients_company_id] = useState<string>('');
    const [first_push_interval, setFirst_push_interval] = useState<string>('');
    const [tokens, setTokens] = useState<string>('');
    const [second_push_interval, setSecond_push_interval] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const getBrand = (brandId: string) => {
        BrandsAPI.getBrand(brandId)
            .then(response => {
                if (response.status <= 204) {
                    setCurrentBrand(response.data.brand);
                    setName(response.data.brand.name);
                    setAssistant_id(response.data.brand.assistant_id);
                    setWazzup_api_key(response.data.brand.wazzup_api_key);
                    setOpenai_api_key(response.data.brand.openai_api_key);
                    setYclients_api_key(response.data.brand.yclients_api_key);
                    setYclients_company_id(response.data.brand.yclients_company_id);
                    setFirst_push_interval(response.data.brand.first_push_interval);
                    setSecond_push_interval(response.data.brand.second_push_interval);
                    setTokens(`${response.data.in_tokens} / ${response.data.out_tokens}`)
                } else {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                }
            })
            .catch(() => {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            })
    }

    const createBrand = () => {
        BrandsAPI.createBrand(
            {
                name,
                assistant_id,
                wazzup_api_key,
                openai_api_key,
                first_push_interval,
                second_push_interval,
                yclients_api_key,
                yclients_company_id,
            }
        )
        .then(response => {
            if (response.status <= 204) {
                dispatch(notificationActions.setMessage({message: 'Успешно'}));
                navigate(`brand/${response.data.id}`);
            } else {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            }
        })
        .catch(() => {
            dispatch(notificationActions.setMessage({message: 'Ошибка'}));
        })
    }

    const editBrand = () => {
        if (brandId) {
            const data: IEditBrandRequest = {};
    
            currentBrand?.name !== name ? data.name = name : null;
            currentBrand?.assistant_id !== assistant_id ? data.assistant_id = assistant_id : null;
            currentBrand?.wazzup_api_key !== wazzup_api_key ? data.wazzup_api_key = wazzup_api_key : null;
            currentBrand?.openai_api_key !== openai_api_key ? data.openai_api_key = openai_api_key : null;
            currentBrand?.yclients_api_key !== yclients_api_key ? data.yclients_api_key = yclients_api_key : null;
            currentBrand?.yclients_company_id !== yclients_company_id ? data.yclients_company_id = yclients_company_id : null;
            currentBrand?.first_push_interval !== first_push_interval ? data.first_push_interval = first_push_interval : null;
            currentBrand?.second_push_interval !== second_push_interval ? data.second_push_interval = second_push_interval : null;
    
            BrandsAPI.editBrand(brandId, data)
                .then(response => {
                    if (response.status <= 204) {
                        setIsEditing(false);
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleSave = () => {
        if (brandId) {
            editBrand();
        } else {
            createBrand()
        }
    }

    useEffect(() => {
        if (brandId) {
            getBrand(brandId);
        }
    }, [])

    return (
        <div className={styles.body}>
            <div className={styles.inputBlock}>
                <h3>{brandId ? '' : 'Создание'}</h3>
                <CustomInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Название'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={assistant_id}
                    onChange={(e) => setAssistant_id(e.target.value)}
                    placeholder='Assistant ID'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={wazzup_api_key}
                    onChange={(e) => setWazzup_api_key(e.target.value)}
                    placeholder='Wazzup API key'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={openai_api_key}
                    onChange={(e) => setOpenai_api_key(e.target.value)}
                    placeholder='Openai API key'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={yclients_api_key}
                    onChange={(e) => setYclients_api_key(e.target.value)}
                    placeholder='Yclients API key'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={yclients_company_id}
                    onChange={(e) => setYclients_company_id(e.target.value)}
                    placeholder='Yclients Company ID'
                    disabled={!!brandId && !isEditing}
                />
                <div className={styles.titleIntervals}>
                    Интервалы дожимных сообщений (минуты)
                </div>
                <div className={styles.intervalsBlock}>
                    <CustomInput
                        value={first_push_interval}
                        type='number'
                        onChange={(e) => setFirst_push_interval(e.target.value)}
                        placeholder='Первый'
                        disabled={!!brandId && !isEditing}
                    />
                    <CustomInput
                        value={second_push_interval}
                        type='number'
                        onChange={(e) => setSecond_push_interval(e.target.value)}
                        placeholder='Второй'
                        disabled={!!brandId && !isEditing}
                    />
                </div>
                {
                    !!brandId && !isEditing ? (
                        <MyButton 
                            handleClickProps={() => setIsEditing(true)}
                        >
                            Изменить
                        </MyButton>
                    ): (
                        <MyButton 
                            disabled={!brandId && (!name || !assistant_id || !wazzup_api_key || !openai_api_key)} 
                            handleClickProps={handleSave}
                        >
                            Сохранить
                        </MyButton>
                    )
                }
            </div>
            {!!brandId &&
                <Channels tokens={tokens}/>
            }
        </div>
    )
}

export default BrandPage;
