import { createAction } from '@reduxjs/toolkit';

interface INotific {
    message: string
}

const notificationActions = {
    setMessage: createAction('setMessage', (payload: INotific) => {
        return {
            payload,
        };
    }),
};

export default notificationActions;
