import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../components/layout';
import Dialogues from 'src/pages/dialogues';
import Brands from 'src/pages/brands';
import BrandPage from 'src/pages/brands/brandPage';

const Router = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path='/' element={<Navigate to='/brands' />} />

                <Route path='/brands' element={<Brands />} />
                <Route path='/brand/:brandId?' element={<BrandPage />} />
                <Route path='/brand/:brandId/channels/:channelId' element={<Dialogues />} />
            </Route>
        </Routes>
    );
};

export default Router;
