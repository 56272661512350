import { createSlice } from '@reduxjs/toolkit';
import notificationActions from '../actions/notificationActions';

export interface notificState {
    message: string;
}

const initialState: notificState = {
    message: '',
};

const name = 'notifications';
const notificationSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Set message
        builder.addCase(notificationActions.setMessage, (state, action) => {
            state.message = action.payload.message;
        });
    },
});

export default notificationSlice.reducer;
