import { Alert, Snackbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import Header from '../ui/header';

const Layout = () => {
    const message = useAppSelector((state) => state.notificationState.message);

    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(notificationActions.setMessage({message: ''}));
    }

    return (
        <div>
            <Header/>
            <Outlet />
            <Snackbar 
                open={!!message} 
                autoHideDuration={3000} 
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={message === 'Успешно' ? "success" : "error"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Layout;
