import { CustomInput } from 'src/components/ui/customInput';
import styles from './style.module.scss';
import { MyButton } from 'src/components/ui/myButton';
import { useEffect, useState } from 'react';
import IDialog from 'src/models/IDialog';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import DialoguesAPI from 'src/api/DialoguesAPI';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { useParams } from 'react-router-dom';

const Dialogues = () => {
    const [dialogues, setDialogues] = useState<IDialog[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const dispatch = useAppDispatch();

    const { brandId, channelId } = useParams();

    const getDialogues = (limit: number, offset: number) => {
        if (brandId && channelId) {
            DialoguesAPI.getDialogues(brandId, channelId, limit, offset)
                .then(response => {
                    if (response.status <= 204) {
                        setDialogues(response.data.dialogues);
                        setCount(response.data.amount);
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleEdit = (dialogueId: string, isActive: boolean) => {
        if (brandId && channelId) {
            DialoguesAPI.editDialog(brandId, channelId, dialogueId, !isActive)
                .then(response => {
                    if (response.status <= 204) {
                        getDialogues(10, 0);
                        setPage(1);
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    useEffect(() => {
        getDialogues(10, 0);
    }, []);

    return (
        <div className={styles.body}>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Имя клиента</th>
                            <th>Дата и время последнего сообщения</th>
                            <th>Статус чата</th>
                            <th>Ассистент</th>
                            <th>Токены</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody className={styles['']}>
                        {dialogues.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <div className={styles.tdTextName}>{item.contact_name}</div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>{'-'}</div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>{item.is_active ? 'Активен' : 'Закрыт'}</div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>{item.is_active ? 'Включен' : 'Выключен'}</div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>{`${item.in_tokens} / ${item.out_tokens}`}</div>
                                </td>
                                <td>
                                    <div 
                                        className={`${styles.tdText} ${styles.cursor}`} 
                                        onClick={() => handleEdit(item.id, item.is_active)}
                                    >
                                        {item.is_active ? '[Отключить]' : '[Включить]'}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <PaginationComponent
                    count={count}
                    limit={10}
                    page={page}
                    setPage={setPage}
                    onChange={getDialogues}
                />
            </div>
        </div>
    )
}

export default Dialogues;
