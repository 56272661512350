import styles from './style.module.scss';
import { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    style?: any;
}

export const CustomInput = (props: Props) => {
    const {
        placeholder,
        value = '',
        name,
        onChange,
        disabled,
        error,
        type = 'text',
        style,
    } = props;

    return (
        <div className={styles.wrapperInput} style={style}>
            <input
                disabled={disabled}
                className={`${styles.customInput} ${error && styles.error}`}
                placeholder={placeholder}
                value={value}
                name={name}
                type={type}
                onChange={onChange}
            />
            {(!!value || value === 0) &&
                <div className={styles.lable}>
                    {placeholder}
                </div>
            }
        </div>
    );
};
