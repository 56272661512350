import styles from './style.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Header = () => {
    let location = useLocation();

    const navigate = useNavigate();

    const { brandId } = useParams();

    return (
        <div className={styles.header}>
            <span onClick={() => navigate('/brands')} className={styles.link}>Список брендов</span>
            {location.pathname !== '/brands' && 
                <>
                    <span>/ </span>
                    <span
                        onClick={() => navigate(`/brand/${brandId}`)} 
                        className={styles.link}
                    >
                        Бренд
                    </span>
                </>
            }
            {location.pathname.includes('channels') && 
                <>
                    <span>/ </span>
                    <span 
                        className={styles.link}
                    >
                        Канал
                    </span>
                </>
            }
        </div>
    )
}

export default Header;
