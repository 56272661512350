import styles from './style.module.scss';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: ReactNode;
    plus?: boolean;
    handleClickProps?: () => void;
    disabled?: boolean;
    className?: string;
}

export const MyButton = (props: Props) => {
    const { children, handleClickProps, disabled = false, type, className } = props;

    return (
        <button
            disabled={disabled}
            className={classNames(styles.mainStyleButton, className)}
            type={type}
            onClick={handleClickProps}
        >
            {children}
        </button>
    );
};
