import { AxiosResponse } from 'axios';
import axiosConfig from './axiosConfig';
import IDialoguesResponse from 'src/models/response/IDialoguesResponse';

class DialoguesAPI {
    public static getDialogues(brandId: string, channelId: string, limit: number, offset: number): Promise<AxiosResponse<IDialoguesResponse>> {
        return axiosConfig.get(`/brands/${brandId}/channels/${channelId}/dialogues`, {
            params: {
                limit,
                offset,
            }
        });
    }

    public static editDialog(brandId: string, channelId: string, dialogue_id: string, is_active: boolean): Promise<AxiosResponse<any>> {
        return axiosConfig.patch(`/brands/${brandId}/channels/${channelId}/dialogues/${dialogue_id}`, null, {
            params: {is_active}
        });
    }
}

export default DialoguesAPI;
