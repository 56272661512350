import { AxiosResponse } from 'axios';
import axiosConfig from './axiosConfig';
import IBrandsResponse from 'src/models/response/IBrandsResponse';
import IEditBrandRequest from 'src/models/request/IEditBrandRequest';
import IBrandResponse from 'src/models/response/IBrandResponse';

class BrandsAPI {
    public static getBrands(limit: number, offset: number): Promise<AxiosResponse<IBrandsResponse>> {
        return axiosConfig.get(`/brands`, {
            params: {
                limit,
                offset,
            }
        });
    }

    public static getBrand(id: string): Promise<AxiosResponse<IBrandResponse>> {
        return axiosConfig.get(`/brands/${id}`);
    }

    public static createBrand(body: IEditBrandRequest): Promise<AxiosResponse<any>> {
        return axiosConfig.post(`/brands`, body);
    }

    public static editBrand(id: string, body: IEditBrandRequest): Promise<AxiosResponse<any>> {
        return axiosConfig.patch(`/brands/${id}`, body);
    }
}

export default BrandsAPI;
